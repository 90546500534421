<template>
  <section>
    <b-row>
      <b-col>
        <h1>{{ $t("permissionGroup.permissionTitle") }}</h1>
        <h5 class="text-primary">
          {{ $t("permissionGroup.permissionSub") }}
        </h5>
      </b-col>
    </b-row>
    <b-card class="mt-2">
      <b-row
        class="justify-content-left"
      >
        <b-col md="3">
          <b-form-group :label="$t('permissionGroup.createGroup')">
            <b-row>
              <b-col md="9">
                <b-form-input
                  v-model="nameInput"
                  :placeholder="$t('permissionGroup.permissionName')"
                  required
                />
              </b-col>
              <b-col
                md="3"
                class="d-flex align-items-center"
              >
                <b-button
                  :disabled="nameInput == ''"
                  variant="primary"
                  @click="createPermissionGroup(nameInput)"
                >
                  {{ $t('dataGeneric.create') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col
          md="9"
          class="d-flex justify-content-end"
          style="height: fit-content;"
        >
          <b-form-group :label="$t('permissionGroup.importPermission')">
            <b-row>
              <b-col>
                <b-form-file
                  class="mr-1"
                  type="file"
                  accept=".json"
                  :browse-text="$t('formFile.browse')"
                  :placeholder="$t('formFile.placeHolder')"
                  @change="handleFileUpload"
                />
              </b-col>
              <b-col>
                <b-button
                  :disabled="jsonData == null"
                  variant="outline-warning"
                  @click="importPermissionGroup()"
                >
                  {{ $t('dataGeneric.import') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <div>
        <b-table
          :items="permissionGroups"
          :fields="fields"
          striped
          responsive
        >
          <template #cell(id)="data">
            <div class="">
              <strong>
                {{ data.item.id }}
              </strong>
            </div>
          </template>
          <template #cell(name)="data">
            <div class="">
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(permissions)="data">
            <div v-if="data.item.permissions.length > 0">
              {{ $t('permissionGroup.permissionCount', {totalCount:data.item.permissions.length}) }}
            </div>
            <div v-else>
              {{ $t('permissionsNotFound') }}
            </div>
          </template>
          <template #cell(editPermissions)="data">
            <b-button
              variant="outline-success"
              @click="openAddPermissionsModal(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                class="text-success"
                size="15"
              />
              {{ $t('Edit') }}
            </b-button>
            <b-link
              class="text-warning ml-2"
              @click="exportPermissionGroup(data.item)"
            >
              <feather-icon
                icon="DownloadIcon"
                class="text-warning mb-50"
                size="15"
              />
              <span>
                {{ $t('purchases.export') }}
              </span>
            </b-link>
            <b-link
              class="text-danger ml-2"
              @click="confirmDeletePermissionGroup(data.item)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger mb-50"
                size="15"
              />
              <span>
                {{ $t('dataGeneric.delete') }}
              </span>
            </b-link>
          </template>
        </b-table>
      </div>
      <div
        class="d-block"
      >
        <add-permissions-modal
          :fetched-group="selectedGroup ? selectedGroup : {}"
          :all-permissions="allPermissions"
          @modal-closed="onModalClosed"
        />
      </div>
    </b-card>
  </section>
</template>
<script>

import * as constants from '@core/utils/constants'

import axios from '@axios'

import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import AddPermissionsModal from '@/views/common/AddPermissionsModal.vue'
import { showToast, messageError, isEmpty } from '@/store/functions'

import {
  BButton,
  BCard,
  BTable,
  BFormInput,
  BFormFile,
  BFormGroup,
  VBTooltip,
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BFormInput,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BLink,
    AddPermissionsModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: this.$t('administrators.name') },
        { key: 'permissions', label: this.$t('Permisos') },
        { key: 'editPermissions', label: this.$t('permissionGroup.editPermissions') },
      ],
      permissionGroups: null,
      allPermissions: [],
      selectedGroup: null,
      userData: getUserData(),
      nameInput: '',
      jsonData: null,
    }
  },
  async mounted() {
    this.fetchPermissionGroups()
    this.fetchAllPermissions()
  },
  methods: {
    fetchPermissionGroups() {
      axios
        .post('', {
          query: `
            query{
                allGroups(orderBy:"name"){
                    edges{
                        node{
                            id
                            name
                            permissions{
                                id
                                name
                                codename
                            }
                        }
                    }
                }
                }`,
        })
        .then(response => {
          const arrayExclude = getUserData().isSuperuser ? [] : constants.exludeList
          const allGroups = response.data.data.allGroups.edges
            .filter(group => {
              const { name } = group.node
              return (
                !arrayExclude.includes(name) // Evita los nombres en arrayExclude
              )
            })
            .map(element => ({
              node: {
                id: element.node.id,
                name: element.node.name,
                permissions: element.node.permissions,
              },
            }))

          const tempArray = []
          allGroups.forEach(element => {
          // eslint-disable-next-line no-param-reassign
            element.node.name = i18n.t(element.node.name)
            tempArray.push(element.node)
          })
          this.permissionGroups = tempArray
        })
        .catch(error => {
          console.error(error)
          showToast(error, 2, this)
        })
    },
    fetchAllPermissions() {
      axios
        .post('', {
          query: `
            query{
                allPermissions{
                    edges{
                        node{
                            id
                            name
                            codename
                        }
                    }
                }
                }`,
        })
        .then(response => {
          const allPermissions = response.data.data.allPermissions.edges
          allPermissions.forEach(permission => this.allPermissions.push(permission.node))
        })
        .catch(error => {
          console.error(error)
          showToast(error, 2, this)
        })
    },
    createPermissionGroup(name, permissionsIds = null) {
      axios
        .post('', {
          query: `
            mutation{
                createGroup(input: {name: "${name}",
                ${permissionsIds === null ? '' : `permissions: [${permissionsIds}]`}
              }){
                    group{
                      id
                    }
                }
            }`,
        })
        .then(response => {
          messageError(response, this)
          if (isEmpty(response.data.errors)) {
            this.fetchPermissionGroups()
            showToast(this.$t('permissionGroup.createGroupSuccess'), 1, this)
          }
        })
        .catch(() => {
          showToast(this.$t('permissionGroup.createGroupError'), 2, this)
        })
    },
    confirmDeletePermissionGroup(group) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('permissionGroup.deleteGroupConfirm', { permissionGroup: group.name }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.delete'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.deletePermissionGroup(group)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    deletePermissionGroup(group) {
      axios
        .post('', {
          query: `
            mutation{
                deleteGroup(id:"${group.id}"){
                  found
                }
            }`,
        })
        .then(response => {
          messageError(response, this)
          if (isEmpty(response.data.errors)) {
            this.fetchPermissionGroups()
            showToast(this.$t('permissionGroup.deleteGroupSuccess'), 1, this)
          }
        })
        .catch(() => {
          showToast(this.$t('permissionGroup.deleteGroupError'), 2, this)
        })
    },
    exportPermissionGroup(group) {
      axios
        .post('', {
          query: `
            query{
                allGroups(id:"${group.id}", orderBy:"name"){
                    edges{
                        node{
                            id
                            name
                            permissions{
                                id
                                name
                                codename
                            }
                        }
                    }
                }
                }`,
        })
        .then(response => {
          const arrayExclude = getUserData().isSuperuser ? [] : constants.exludeList
          const fetchedGroup = response.data.data.allGroups.edges
            .filter(element => {
              const { name } = element.node
              return (
                !arrayExclude.includes(name) // Evita los nombres en arrayExclude
              )
            })
            .map(element => ({
              node: {
                name: element.node.name,
                permissions: element.node.permissions.map(permission => ({
                  id: permission.id,
                })),
              },
            }))
          this.downloadPermissionGroupAsJson(fetchedGroup[0].node)
        })
        .catch(error => {
          console.error(error)
          showToast(error, 2, this)
        })
    },
    downloadPermissionGroupAsJson(extractedData) {
      const jsonString = JSON.stringify(extractedData, null, 2) // Pretty-print JSON
      const blob = new Blob([jsonString], { type: 'application/json' })
      const url = URL.createObjectURL(blob)

      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = `${extractedData.name}.json`
      document.body.appendChild(a)
      a.click()

      // Clean up
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    },
    importPermissionGroup() {
      if (!this.jsonData) return
      const nameInput = this.jsonData.name
      let permissionsIds = this.jsonData.permissions.map(
        permission => permission.id,
      )
      const index = this.permissionGroups.findIndex(element => element.name === nameInput)
      if (index !== -1) {
        const foundGroup = this.permissionGroups[index]
        permissionsIds = [...new Set([
          ...permissionsIds,
          ...foundGroup.permissions.map(perm => perm.id),
        ])]

        axios
          .post('', {
            query: `
            mutation{
                updateGroup(id: "${foundGroup.id}", input:{permissions: [${permissionsIds}]}){
                    group{
                      id
                    }
                }
            }`,
          })
          .then(response => {
            messageError(response, this)
            showToast(this.$t('permissionGroup.addPermissionSuccess'), 1, this)

            this.fetchPermissionGroups()
          })
          .catch(() => {
            showToast(this.$t('permissionGroup.addPermissionError'), 2, this)
          })
      } else {
        this.createPermissionGroup(nameInput, permissionsIds)
      }
    },
    openAddPermissionsModal(group) {
      this.selectedGroup = group
      this.$nextTick(() => {
        this.$bvModal.show('add-permissions-modal')
      })
    },
    onModalClosed(groupsChanged) {
      if (groupsChanged) this.fetchPermissionGroups()
      this.selectedGroup = null
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          try {
            this.jsonData = JSON.parse(e.target.result)
          } catch (error) {
            console.error('Error parsing JSON:', error)
            showToast(this.$t('permissionGroup.jsonParseError'), 2, this)
          }
        }
        reader.readAsText(file)
      } else this.jsonData = null
    },
  },
}
</script>
  <style lang="scss" scoped>
    @import "@core/scss/vue/libs/vue-select.scss";
    .scrollable-list {
        max-height: 200px;
        max-width: 500px;
        overflow-y: auto;
        border: 1px solid #bcbad4;
        border-radius: 4px;
    }

    .scrollable-list::-webkit-scrollbar {
        width: 8px;
    }

    .scrollable-list::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .scrollable-list::-webkit-scrollbar-thumb {
        background: #bcbad4;
        border-radius: 4px;
    }

    .scrollable-list::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
  </style>
