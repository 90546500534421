<template>
  <b-modal
    id="add-permissions-modal"
    ref="permissionsModal"
    class="custom-modal"
    centered
    size="lg"
    static
    :ok-title="$t('code.accept')"
    :title="$t('permissionGroup.addPermissions')"
    ok-only
    @show="onModalOpened()"
    @hidden="confirmSelection()"
  >
    <b-row
      v-if="!isEmpty(group)"
      class="justify-content-left ml-25"
    >
      <b-col>
        <b-form-group :label="$t('permissionGroup.permissionName') + ' *'">
          <b-row>
            <b-col>
              <b-form-input
                v-model="nameInput"
                :placeholder="$t('permissionGroup.permissionName')"
                required
              />
              <small
                v-if="nameInput == ''"
                class="text-danger"
              >
                {{ $t('required') }}
              </small>
            </b-col>
            <b-col
              md="2"
              class="d-flex align-items-center mr-1"
            >
              <b-button
                :disabled="nameInput == ''"
                variant="primary"
                @click="editGroupName()"
              >
                {{ $t('dataGeneric.change') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <b-container
      v-if="!isEmpty(group)"
      fluid
    >
      <b-row>
        <!-- First Column -->
        <b-col
          md="6"
        >
          <b-row>
            <b-col>
              <h5 class="text-primary">
                {{ $t("permissionGroup.assignedPermissions") }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-input
                v-model="deleteSearchInput"
                :placeholder="$t('permissionGroup.searchPermission')"
                class="mb-2"
              />
            </b-col>
          </b-row>
          <b-list-group
            v-if="filterDeletePermissions().length > 0"
            class="scrollable-list mb-50"
          >
            <b-list-group-item
              v-for="res in filterDeletePermissions()"
              :key="`${group.id}_${res.id}`"
              class="cursor-pointer"
              :active="isSelected(res, true)"
              @click="toggleSelection(res, true)"
            >
              <div class="">
                <b-row>
                  <b-col>
                    <strong>{{ res.name }}</strong>
                    <span class="text-muted"> {{ res.codename }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div v-else>
            <span v-if="group.permissions.length == 0">
              {{ $t('permissionsNotFound') }}
            </span>
            <span v-else>
              {{ $t('permissionsQueryNotFound') }}
            </span>
          </div>
          <b-row v-if="filterDeletePermissions().length > 0">
            <b-link
              v-if="selectedGroupPermissions.length == 0"
              class="ml-2 mr-4"
              style="float: left;"
              variant="outline-success"
              @click="selectAll(true)"
            >
              <feather-icon
                icon="CheckSquareIcon"
                class="mb-25 text-primary"
              />
              {{ $t('slider.allSelect') }}
            </b-link>
            <b-link
              v-else
              class="ml-2 mr-3"
              style="float: left;"
              variant="outline-success"
              @click="selectedGroupPermissions = []"
            >
              <feather-icon
                icon="SquareIcon"
                class="mb-25 text-primary"
              />
              {{ $t('cleanSelection') }}
            </b-link>
            <b-button
              v-if="selectedGroupPermissions.length > 0"
              style="float: right;"
              variant="outline-success"
              @click="editGroupPermissions(true)"
            >
              {{ $t('deletePermissions') }}
            </b-button>
          </b-row>
        </b-col>

        <!-- Second Column -->
        <b-col
          md="6"
        >
          <b-row>
            <b-col>
              <h5 class="text-primary">
                {{ $t("permissionGroup.assignedPermissionsNot") }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-input
                v-model="addSearchInput"
                :placeholder="$t('permissionGroup.searchPermission')"
                class="mb-2"
              />
            </b-col>
          </b-row>
          <div v-if="filterAddPermissions().length > 0">
            <b-list-group class="scrollable-list mb-50">
              <b-list-group-item
                v-for="res in filterAddPermissions()"
                :key="`${group.id}_${res.id}`"
                class="cursor-pointer"
                :active="isSelected(res)"
                @click="toggleSelection(res)"
              >
                <b-row>
                  <b-col>
                    <div>
                      <strong>{{ res.name }}</strong>
                      <span class="text-muted"> {{ res.codename }}</span>
                    </div>
                  </b-col>
                  <b-col>
                    <span class="text-success">
                      <feather-icon
                        v-if="isSelected(res)"
                        style="float: right;"
                        icon="CheckIcon"
                      />
                    </span>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
            <b-row>
              <b-link
                v-if="selectedPermissions.length == 0"
                class="ml-2 mr-4"
                style="float: left;"
                variant="outline-success"
                @click="selectAll()"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mb-25 text-primary"
                />
                {{ $t('slider.allSelect') }}
              </b-link>
              <b-link
                v-else
                class="ml-2 mr-3"
                style="float: left;"
                variant="outline-success"
                @click="selectedPermissions = []"
              >
                <feather-icon
                  icon="SquareIcon"
                  class="mb-25 text-primary"
                />
                {{ $t('cleanSelection') }}
              </b-link>
              <b-button
                v-if="selectedPermissions.length > 0"
                style="float: right;"
                variant="outline-success"
                @click="editGroupPermissions()"
              >
                {{ $t('permissionGroup.addPermissionsConfirm') }}
              </b-button>
            </b-row>
          </div>
          <div v-else>
            <span>
              {{ $t('permissionsQueryNotFound') }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BCol,
  BRow,
  BModal,
  BButton,
  BContainer,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BLink,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'

import i18n from '@/libs/i18n'
import axios from '@axios'

import { messageError, showToast, isEmpty } from '@/store/functions'

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BFormGroup,
    BContainer,
    BCol,
    BRow,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    allPermissions: {
      type: Array,
      required: true,
    },
    fetchedGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEmpty,
      groupModified: false,
      notAssignedPermissions: [],
      selectedPermissions: [],
      selectedGroupPermissions: [],
      group: null,
      addSearchInput: '',
      deleteSearchInput: '',
      nameInput: '',
    }
  },
  watch: {
    fetchedGroup(newValue) {
      if (!isEmpty(newValue)) {
        this.group = newValue
      }
    },
    group(newValue) {
      if (!isEmpty(newValue)) {
        this.nameInput = newValue.name
        this.getNotAssignedPermissions(newValue)
      }
    },
  },
  methods: {
    onModalOpened() {
      this.group = this.fetchedGroup
    },
    getNotAssignedPermissions(group) {
      const permissions = this.allPermissions.filter(
        permission => !group.permissions.some(
          groupPermission => groupPermission.codename === permission.codename,
        ),
      )
      this.notAssignedPermissions = permissions
    },
    editGroupPermissions(deletePermissions = false) {
      const permissionsIds = !deletePermissions
        ? [
          ...this.group.permissions.map(perm => perm.id),
          ...this.selectedPermissions.map(perm => perm.id),
        ]
        : this.group.permissions.filter(perm => !this.selectedGroupPermissions.map(p => p.id).includes(perm.id)).map(perm => perm.id)
      axios
        .post('', {
          query: `
            mutation{
                updateGroup(id: "${this.group.id}", input:{permissions: [${permissionsIds}]}){
                    group{
                      id
                    }
                }
            }`,
        })
        .then(response => {
          messageError(response, this)
          showToast(!deletePermissions ? this.$t('permissionGroup.addPermissionSuccess') : this.$t('permissionGroup.deletePermissionSuccess'), 1, this)
          this.groupModified = true

          this.selectedPermissions = []
          this.selectedGroupPermissions = []

          this.fetchPermissionGroup(this.group.id)
        })
        .catch(() => {
          showToast(!deletePermissions ? this.$t('permissionGroup.addPermissionError') : this.$t('permissionGroup.deletePermissionError'), 2, this)
        })
    },
    editGroupName() {
      axios
        .post('', {
          query: `
            mutation{
                updateGroup(id: "${this.group.id}", input:{name: "${this.nameInput}"}){
                    group{
                      id
                    }
                }
            }`,
        })
        .then(response => {
          this.groupModified = true
          messageError(response, this)
          showToast(this.$t('permissionGroup.groupEditSuccess'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('permissionGroup.groupEditError'), 2, this)
        })
    },
    fetchPermissionGroup(id) {
      axios
        .post('', {
          query: `
            query{
                allGroups(id:"${id}", orderBy:"name"){
                    edges{
                        node{
                            id
                            name
                            permissions{
                                id
                                name
                                codename
                            }
                        }
                    }
                }
                }`,
        })
        .then(response => {
          const arrayExclude = getUserData().isSuperuser ? [] : constants.exludeList
          const allGroups = response.data.data.allGroups.edges
            .filter(group => {
              const { name } = group.node
              return (
                !arrayExclude.includes(name) // Evita los nombres en arrayExclude
              )
            })
            .map(element => ({
              node: {
                id: element.node.id,
                name: element.node.name,
                permissions: element.node.permissions,
              },
            }))

          if (allGroups.length > 0) {
            this.group = {
              ...allGroups[0].node,
              name: i18n.t(allGroups[0].node.name),
            }
          }
        })
        .catch(error => {
          console.error(error)
          showToast(error, 2, this)
        })
    },
    filterAddPermissions() {
      if (this.addSearchInput.length < 3) { return this.notAssignedPermissions }

      const query = this.addSearchInput.toLowerCase()

      const filtered = this.notAssignedPermissions.filter(
        permission => permission.name.toLowerCase().includes(query)
          || permission.codename.toLowerCase().includes(query),
      )

      return filtered
    },
    filterDeletePermissions() {
      if (this.deleteSearchInput.length < 3) return this.group.permissions
      const query = this.deleteSearchInput.toLowerCase()

      const filtered = this.group.permissions.filter(
        permission => permission.name.toLowerCase().includes(query)
          || permission.codename.toLowerCase().includes(query),
      )

      return filtered
    },
    isSelected(permission, fromCurrentGroup = false) {
      return !fromCurrentGroup
        ? this.selectedPermissions.some(selected => selected.codename === permission.codename)
        : this.selectedGroupPermissions.some(selected => selected.codename === permission.codename)
    },
    toggleSelection(permission, fromCurrentGroup = false) {
      const index = !fromCurrentGroup
        ? this.selectedPermissions.findIndex(selected => selected.codename === permission.codename)
        : this.selectedGroupPermissions.findIndex(selected => selected.codename === permission.codename)

      if (index === -1) {
        return !fromCurrentGroup ? this.selectedPermissions.push(permission) : this.selectedGroupPermissions.push(permission)
      }
      return !fromCurrentGroup ? this.selectedPermissions.splice(index, 1) : this.selectedGroupPermissions.splice(index, 1)
    },
    selectAll(fromCurrentGroup = false) {
      const filteredPermissions = !fromCurrentGroup ? this.filterAddPermissions() : this.filterDeletePermissions()
      filteredPermissions.forEach(permission => {
        const index = !fromCurrentGroup
          ? this.selectedPermissions.findIndex(selected => selected.codename === permission.codename)
          : this.selectedGroupPermissions.findIndex(selected => selected.codename === permission.codename)
        if (index === -1) {
          if (!fromCurrentGroup) this.selectedPermissions.push(permission)
          else this.selectedGroupPermissions.push(permission)
        }
      })
    },
    confirmSelection() {
      this.resetModal()
      this.$emit('modal-closed', this.groupModified)
    },
    resetModal() {
      this.notAssignedPermissions = []
      this.selectedPermissions = []
      this.addSearchInput = ''
      this.deleteSearchInput = ''
      this.group = null
    },
  },
}
</script>

  <style>
      .scrollable-list {
        max-height: 400px;
        max-width: 500px;
        overflow-y: auto;
        border: 1px solid #bcbad4;
        border-radius: 4px;
    }

    .scrollable-list::-webkit-scrollbar {
        width: 8px;
    }

    .scrollable-list::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .scrollable-list::-webkit-scrollbar-thumb {
        background: #bcbad4;
        border-radius: 4px;
    }

    .scrollable-list::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .custom-modal .modal-content {
      max-height: 600px;
      height: 600px;
    }
  </style>
